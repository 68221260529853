body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: white;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*

.glide:focus {
 border: 1px solid red;
}

.glide .glide__slide--active {
 border: 1px solid blue;
  
}
*/

*  {outline: none;}



.asset-box {
 box-sizing: border-box;

  border-radius: 20px;
  --background: linear-gradient(0deg, #300305, #160102);
  padding: 20px;
  font-family: "IBM Plex Sans";
  color: white;
  background: linear-gradient(0deg, #300305, #160102);
  --border-color: rgb(255, 0, 76);
  padding: 20px;
  font-family: "IBM Plex Sans";
  color: white;
  border-radius: 10px;
  /* outline: 2px solid red; */
  background: hsl(252deg 19% 6%);
  background: hsla(252deg, 19%, 20%, .4);
  max-height: 200px;
}

.news-box {
  border-radius: 20px;
  --background: linear-gradient(0deg, #300305, #160102);
  padding: 20px;
  font-family: "IBM Plex Sans";
  color: white;
  background: linear-gradient(0deg, #300305, #160102);
  --border-color: rgb(255, 0, 76);
  padding: 20px;
  font-family: "IBM Plex Sans";
  color: white;
  border-radius: 10px;
  /* outline: 2px solid red; */
  background: hsl(252deg 19% 6%);
  background: hsla(252deg, 19%, 20%, .4);;
  height: 100%;
  box-sizing: border-box;
  font-weight: bolder;
  font-size: 30px;
}

.asset-box .change {
  color: rgb(255, 0, 76);
}

.asset-box .sparkline>svg {
  stroke: rgb(255, 0, 76);
}

.title {
  position: absolute;
  z-index: 1000;
}

.name {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 4px;
  /* font-weight: 600; */

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.volume {
  font-family: "IBM Plex Mono";
  margin-top: 4px;
  color: currentColor;
  /* opacity: .8; */
}

.price {
  font-family: "IBM Plex Mono";
}

.volume::after {
  content: ' vol';
}



.asset-box .sparkline>svg {
  stroke: rgb(255, 0, 76);
  fill: none;
}



/* just the line */
.sparkline {
  stroke: red;
  fill: none;
}

/* line with highlight area */
.sparkline {
  stroke: red;
  fill: rgba(255, 0, 0, .3);
}

/* change the spot color */
.sparkline--spot {
  stroke: blue;
  fill: blue;
}

/* change the cursor color */
.sparkline--cursor {
  stroke: orange;
}

.glide::after {
  content: '';
  position: absolute;
  border-radius: 10px;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid white;
  width: 231px;
  top: 0;
  left: 0;
}

.glide:focus .glide__slide--active  .asset-box {
  --border: 2px solid white;
  --padding: 18px;
 
 
 }
 body {
   padding: 10px;
   box-sizing: border-box;
 }
 